<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input @change="onQuerySubmit" v-model="query.enterprise_name" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row :gutter="20">
          <el-col v-for="(item, index) in monitorList.data" :key="index" :span="24" class="bg-col">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-row>
                  <el-col class="avatar">
                    <el-image :src="item.st === '31' ? gas : water" fit="cover" class="imge"></el-image>
                  </el-col>
                  <el-col :span="15">
                    <el-row>
                      <el-col :span="24">
                        <span>{{item.equipment_name}}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <span>{{item.enterprise_name}}</span>
                      </el-col>
                    </el-row>
                  </el-col>                  
                  <!-- <el-col :span="13">
                  </el-col> -->
                </el-row>
              </el-col>
              <el-col :span="11" class="date" v-if="item.maintenanceTime">
                <p class="p1">最后维护时间：<span>{{item.maintenanceTime}}</span></p>
                <p class="p2">最新数据时间：<span>{{item.data_time}}</span></p>
              </el-col>  
              <el-col :span="11" class="date" v-else>
                <p class="p3">最新数据时间：<span>{{item.data_time}}</span></p>
              </el-col>              
              <el-col :span="1" class="detail_btn">
                <el-row>
                  <el-col class="detail_col">
                    <el-button @click="userDatail(index)" type="text" size="small">详情</el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row :gutter="10" v-if="item.data.length != 0">
              <el-col :span="24">
                <el-row>
                  <el-col v-for="(factor, index) in item.data" :key="index" :md="4" :lg="3" :xl="3">
                    <ul class="data" v-if="factor.is_over === false">
                      <li class="data-total">{{factor.value}}<span>{{factor.unit}}</span></li>
                      <li class="data-name">{{factor.name}}</li>
                    </ul>
                    <ul class="data" v-else>
                      <li class="data-total lightcoral">{{factor.value}}<span>{{factor.unit}}</span></li>
                      <li class="data-name">{{factor.name}}</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-col>                   
            </el-row>
            <el-row :gutter="10" v-if="item.data.length == 0">
              <el-col :span="24">
                <el-empty description="暂无数据" :image-size="100" style="text-align:center;"></el-empty>
              </el-col>                   
            </el-row>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="changeCurrent" :current-page="query.page" :total="monitorList.total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiMonitorMonitorList } from "src/api/index";
import gas from "../../../assets/images/icon_g.png";
import water from "../../../assets/images/icon_w.png";
import md5 from 'js-md5';
export default {
  components: {},
  data() {
    return {
      loading: false,
      query: {
        page: 1,
        enterprise_name: '',
        logotype: 'outlet',
      },
      res: {},
      Enterprise: [],
      monitorList: {},
      gas: gas,
      water: water,
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'outlet') {
      _this.query =  query;
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getData'
  },
  methods: {
    ...mapActions(["pageInit"]),
    onQuerySubmit() {
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&enterprise_name=' + this.query.enterprise_name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {       
        this.$router.replace(route);
      }  
    },
    getData() {
      const _this = this;
      _this.loading = true;
      apiMonitorMonitorList(_this.query)
      .then((res) => {
        _this.monitorList = res;
        _this.loading = false;
      })
      .catch((err) => {
        _this.loading = false;
        if (err.message === '没有操作权限') {
          return
        } else {         
          this.$message.error(err.message);
        }
      })
    },
    userDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/monitor/outlet-detail/real/' + this.monitorList.data[item].id);
    },
    changeCurrent(item) {
      this.query.page = item;     
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&enterprise_name=' + this.query.enterprise_name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {       
        this.$router.replace(route);
      }
    }
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
.bg-col{
  box-shadow: 0 0 4px 0 lightgray;
  border-radius: 5px;
  margin: 0 0 30px 0;
  padding: 20px 0 10px 0;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
}
.avatar {
  width: 60px;
  height: 50px;
  .imge {
    width: 50px;
    height: 50px;
  }
}
/deep/ .el-card__body {
  padding: 0 20px 20px 20px;
}
.el-divider--horizontal {
  margin: 10px 0 0 0;
}
.date {
  line-height: 0px;
  font-size: 18px;
  position: relative;
  .p1 {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .p2 {
    position: absolute;
    right: 15px;
    top: -12px;
  }
  .p3 {
    position: absolute;
    right: 15px;
    top: -2px;
  }
  span {
    color:#5cb6ff;
  }
}
.detail_btn {
  position: absolute;
  right:0;
  .detail_col {
    float:right;
  }
}
.data{
  list-style: none;
  text-align: center;
  padding-left: 5px;
}
.data-total {
  font-size: 24px;
  padding: 0 10px;
  color: #5cb6ff;
}
.data-total span {
  font-size: 10%;
}
.data-name{
  font-size: 15px;
}
.lightcoral {
  color: #ef7474;
}
/deep/.el-empty__image {
  margin:  0 auto;
}
</style>